import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Typography,
  Link,
  Grid,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import history from "../../utils/history";
import AuthLayout from "../Shared/Layout/AuthLayout/AuthLayout";
// import { MuiOtpInput } from "mui-one-time-password-input";
import defaultImage from "assets/icons1/avatars/default.png";
import urls from "../../global/constants/UrlConstants";
import { CustomInput } from "../../utils/styles";
import { verifyTOTP } from "../../utils/service";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { loginAction, selectAuthenticated } from "../../redux/authSlice";
import {
  convertResourceToObjectFormat,
  isTruthy,
  isValidOTP,
  openErrorNotificationWithGenericError,
} from "../../helpers/methods";
import loginLogo from "assets/images/loginLogo.svg";
import { CustomButton } from "global/components";
import otptyles from "./OTP.styles";

interface CustomProps {
  location: any;
}

const OTP = (props: CustomProps) => {
  const dispatch = useAppDispatch();
  const classes = otptyles;
  const email = props.location.state?.email ?? "";
  const isLoggedIn = useAppSelector(selectAuthenticated);
  const [payload, setPayload] = useState({
    email: email,
    otp: "",
  });
  const [processing, setProcessing] = useState<boolean>(false);
  const componentRef = useRef<any>(null);

  const verifyOTP = async () => {
    try {
      if (!isValidOTP(payload.otp)) {
        return openErrorNotificationWithGenericError(
          "Please enter the correct OTP."
        );
      }
      setProcessing(true);
      const user = await verifyTOTP(payload.email, parseInt(payload.otp));
      const formattedResources = convertResourceToObjectFormat(user.resources);
      if (!isTruthy(formattedResources)) {
        openErrorNotificationWithGenericError("Something went wrong!");
        setProcessing(false);
        return;
      }
      dispatch(
        loginAction({
          authenticated: true,
          accessToken: user.authToken,
          userName: user.name,
          userRole: user.role,
          userAccount: user.account,
          userEmail: user.email,
          resources: formattedResources,
          trial: user.trial,
          app: user.app,
        })
      );
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
      openErrorNotificationWithGenericError(error.message);
    }
  };

  const staticImageComponent = () => {
    return (
      <>
        <Grid container sx={classes.staticHeaderWrapper}>
          <Grid item>
            <img src={loginLogo} width="100%" height={"auto"} alt="loginLogo" />
          </Grid>
          <Grid item display={"flex"} gap={2}>
            <Box></Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const setVerificationCode = (event: any) => {
    const inputValue = event.target.value;
    if (inputValue === "" || /^\d+$/.test(inputValue)) {
      setPayload({
        ...payload,
        otp: event.target.value,
      });
    }
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const otpComponent = () => {
    return (
      <Box ref={componentRef}>
        <Box sx={classes.innerGetLoginBox}>
          <Typography sx={classes.getHeading} variant="h1">
            Enter Authenticator Code
          </Typography>
        </Box>
        {renderOTPScreen()}
        <Box>
          <CustomButton
            label={
              <Typography variant="h6" sx={classes.signInText}>
                Verify
              </Typography>
            }
            onClick={verifyOTP}
            disabled={false}
            loading={false}
            customClasses={classes.signBtn}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
            }}
          >
            <Link
              color="white"
              underline="hover"
              variant="subtitle2"
              onClick={() => history.push(urls.LOGIN_VIEW_PATH)}
            >
              Back to Login
            </Link>
          </Box>
        </Box>
        <Box sx={classes.allRightReservedWrapper}>
          <Typography sx={classes.footerTypo} variant="subtitle1">
            &copy; {getYear()} RFPPro. All Rights Reserved
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderOTPScreen = () => {
    return (
        <CardContent>
          <Box >
            <TextField
            sx={{ backgroundColor: "white", borderRadius: "15px"  }}
          placeholder="Please Enter the authenticator code"
            fullWidth
            type="text"
            onChange={(event: any) => setVerificationCode(event)}
            value={payload.otp}
            inputProps={{
              pattern: '[0-9]*',
              maxLength: 6
            }}
            />
          </Box>
        </CardContent>
    );
  };

  if (isLoggedIn) {
    history.push(urls.LANDING_VIEW_PATH);
    return null;
  } else {
    return (
      <Grid container sx={classes.mainWrapper}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={classes.staticImageComponentWrapper}
        >
          {staticImageComponent()}
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={classes.getComponentBasedOnURLWrapper}
        >
          <Grid
            container
            sx={{
              ...classes.innerWrapper,
            }}
          >
            <Grid item xl={3.4} lg={3.3} md={6} sm={9} xs={10}>
              {otpComponent()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default OTP;
