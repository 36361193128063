import { useEffect, useState } from "react";
import QRCode from "qrcode";
import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import CustomDialog from "../../../global/components/CustomModal/CustomModal";
import { fetchQRCodeForMFA, verifyMFACode } from "../SettingsService";
import notifiers from "../../../global/constants/NotificationConstants";
// import { MuiOtpInput } from "mui-one-time-password-input";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CustomInput } from "../../../global/components";
import { isTruthy, openErrorNotificationWithGenericError, openSuccessNotification } from "../../../helpers/methods";
import { useAppSelector } from "utils/hooks";
import { selectBackgroundColor } from "redux/themeChangeSlice";
import { getCustomError } from "utils/customError";
import { TextField } from "@material-ui/core";

interface CustomProps {
  handleDialogClose: Function;
  visible: boolean;
  updateMFAStatus: Function;
  mfaEnabled: boolean;
}

const ManageMFAModal = (props: CustomProps) => {
  const disableMFA = props.mfaEnabled;
  const [qr, setQR] = useState<string>();
  const bgcolor = useAppSelector(selectBackgroundColor);
  const [processing, setProcessing] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<{
    [key: string]: {
      value: string;
      error: string;
    };
  }>({
    code: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  });
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    if (props.visible) {
      makeQRCode();
    }
  }, [props.visible]);

  const makeQRCode = async () => {
    try {
      const response = await fetchQRCodeForMFA();
      await generateQRCodeImage(response.message);
    } catch (error: any) {
      openErrorNotificationWithGenericError(error);
    }
  };

  const handleValidate = () => {
    let errors = credentials;
    let isValid: boolean = true;
    const password = credentials.password.value;
    const verificationCode = credentials.code.value;
    if (!verificationCode && !password) {
      errors.code.error = "Please enter TOTP";
      errors.password.error = "Please enter password";
      isValid = false;
    }
    if (!verificationCode) {
      errors.code.error = "Please enter TOTP";
      isValid = false;
    }
    if (!password) {
      errors.password.error = "Please enter password";
      isValid = false;
    }
    setCredentials({ ...errors });
    return isValid;
  };

  const handleValidateForDisableMFA = () => {
    let errors = credentials;
    let isValid: boolean = true;
    const password = credentials.password.value;
    if (!password) {
      errors.password.error = "Please enter password";
      isValid = false;
    }
    setCredentials({ ...errors });
    return isValid;
  };

  const updateMFA = async () => {
    try {
      if (disableMFA ? handleValidateForDisableMFA() : handleValidate()) {
        setProcessing(true);
        const response = await verifyMFACode(
          credentials.password.value,
          credentials.code.value,
          !disableMFA
        );
        props.updateMFAStatus(!disableMFA);
        openSuccessNotification(response.message);
        handleClose();
        setProcessing(false);
      }
    } catch (error: any) {
      setProcessing(false);
      getCustomError(error);
    }
  };

  const generateQRCodeImage = async (url: string) => {
    try {
      const qrCodeImage = await QRCode.toDataURL(url);
      setQR(qrCodeImage);
    } catch (error: any) {
      openErrorNotificationWithGenericError(error);
    }
  };

  const setVerificationCode = (event: any) => {
    const inputValue = event.target.value;
    const newValue = inputValue.replace(/\D/g, '');
    if (newValue.length <= 6) {
      setCredentials({
        ...credentials,
        code: {
          value: event.target.value,
          error: "",
        },
      })
    }
  }

  const getBody = () => {
    return (
      <Stack direction="column" spacing={2} pt={2}>
        <Typography variant="body2" sx={{color: bgcolor ? "#CBCBCB" : "#000000"}}>
          {disableMFA
            ? "Please enter your password to disable the multi-factor authentication."
            : "To enable the multi-factor authentication, you need to scan this QR Code with your Authentication App and enter the verification code and your password below."}
        </Typography>
        {!disableMFA && (
          <Stack
            alignItems="center"
            sx={{ border: "0.5px solid #E9E9E9", borderRadius: "20px", py: 2 }}
          >
            <img src={qr} height={150} width={150} />
          </Stack>
        )}
        <Box>
          {!disableMFA && <InputLabel required sx={{color: bgcolor ? "#CBCBCB" : "#000000"}}>Password</InputLabel>}
          <CustomInput
            id="password"
            type={visibility ? "text" : "password"}
            placeHolder="Password"
            sx={{
              "& .MuiInputBase-input": { padding: "13px 13px" },
              "& input: : -ms-reveal": { display: "none" },
            }}
            onChange={(event: any) =>
              setCredentials({
                ...credentials,
                password: {
                  value: event.target.value,
                  error: "",
                },
              })
            }
            value={credentials.password.value}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => setVisibility(!visibility)}
                  sx={{ cursor: "pointer" }}
                >
                  <SvgIcon>
                    {visibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            error={
              credentials.password.value.length < 8
                ? credentials.password.error
                : null
            }
            helperText={credentials.password.error}
          />
        </Box>
        {!disableMFA && (
          <Stack spacing={1}>
            <InputLabel required sx={{color: bgcolor ? "#CBCBCB" : "#000000"}}>Verification Code</InputLabel>
            {/* <MuiOtpInput
              length={6}
              onChange={(value: any) =>
                setCredentials({
                  ...credentials,
                  code: {
                    value: value,
                    error: "",
                  },
                })
              }
              sx={{
                "& .MuiFilledInput-input": {
                  p: "14px",
                },
              }}
              value={credentials.code.value}
            /> */}
            <TextField
            type="number"
            onChange={(value: any) =>
              setVerificationCode(value)
            }
            value={credentials.code.value}
            inputProps={{
              pattern: '[0-9]*',
              maxLength: 6
            }}
            />

            {!isTruthy(credentials.code.value) && (
              <FormHelperText error sx={{ paddingLeft: "14px" }}>
                {credentials.code.error}
              </FormHelperText>
            )}
          </Stack>
        )}
      </Stack>
    );
  };

  const getFooter = () => {
    return (
      <Button
        variant="contained"
        onClick={updateMFA}
        disabled={processing}
        size="small"
      >
        {disableMFA ? "Disable" : "Verify"}
      </Button>
    );
  };

  const handleClose = () => {
    setCredentials({
      code: {
        value: "",
        error: "",
      },
      password: {
        value: "",
        error: "",
      },
    });
    props.handleDialogClose();
  };

  return (
    <CustomDialog
      handleDialogClose={handleClose}
      isDialogOpen={props.visible}
      dialogBodyContent={getBody()}
      dialogFooterContent={getFooter()}
      dialogTitleContent={<Typography variant="h5" sx={{color: bgcolor ? "#CBCBCB" : "#000000"}}>Multi Factor Authentication</Typography>}
      maxWidth="xs"
      closable
      closeButtonVisibility
    />
  );
};

export default ManageMFAModal;
