import { skyPrimaryColor } from "utils/styles";

const LearningModalStyle = {
  textStyle: {
    color: skyPrimaryColor,
    fontWeight: "500",
  },
  bodyStyle: {
    padding: "10px 12px 15px 14px",
  },
} as const;
export default LearningModalStyle;
