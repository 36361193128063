import React, { useEffect, useState } from "react";
import strings from "../../global/constants/StringConstants";
import { Box } from "@mui/material";
import CustomTabs from "../../global/components/CustomTabs/CustomTabs";
import settingsStyles from "./Settings.styles";
import Security from "./Security/Security";
import { openErrorNotificationWithGenericError } from "../../helpers/methods";
import { UserProfile } from "./SettingsTypes";
import { fetchProfileDetails } from "./SettingsService";
import { useTitle } from "utils/UseTitle";
import PageHeader from "screens/Shared/PageHeader/PageHeader";
import CustomLoader from "global/components/CustomLoader/CustomLoader";

const Settings = () => {
  useTitle(strings.SETTINGS);
  const [loader, setLoader] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserProfile>(
    {} as UserProfile
  );
  const [tabValue, setTabValue] = useState(strings.security);

  const tabConfig = [
    {
      label: strings.security,
    }
  ];

  useEffect(() => {
    if (tabValue === strings.security) {
      fetchProfile();
    }
  }, [tabValue]);

  const fetchProfile = async () => {
    try {
      setLoader(true);
      const response = await fetchProfileDetails();
      setUserProfile(response);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      openErrorNotificationWithGenericError(error);
    }
  };

  
  const handleChange = (newValue: string) => {
    setTabValue(newValue);
  };

  const getScreens = () => {
    switch (tabValue) {
      case strings.security:
        return <Security 
        mfaEnabled={userProfile.multiFactorLogin ?? false}
            updateMFAStatus={(status: boolean) =>
              setUserProfile({
                ...userProfile,
                multiFactorLogin: status,
              })
            }
        />;
      default:
        break;
    }
  };

  const getSettingsScreen = () => {
    return (
      <Box>
        <CustomTabs
              changeValue={handleChange}
              selected={tabValue}
              tabConfig={tabConfig}
            />
        {getScreens()}
      </Box>
    );
  };

  return loader ? <CustomLoader isLoading={loader}/> : getSettingsScreen();
};

export default Settings;
