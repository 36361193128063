export const usersHeader = [
  {
    name: "Name",
    field: "name",
  },
  {
    name: "Account",
    field: "account",
  },
  {
    name: "Role",
    field: "role",
  },
  {
    name: "Email",
    field: "email",
  },
  {
    name: "Contact Number",
    field: "contactNo",
  },
  {
    name: "Action",
    field: "action",
  },
];
