import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Typography,
  Link,
  Stack,
  TextField,
  Grid,
} from "@mui/material";
import QRCode from "qrcode";
import { useEffect, useRef, useState } from "react";
import history from "utils/history";
import AuthLayout from "./Layout/AuthLayout/AuthLayout";
// import { activateMFA, generateQRCodeForMFA } from "screens/Auth/AuthService";
import {
  convertResourceToObjectFormat,
  isTruthy,
  isValidOTP,
  openErrorNotification,
  openErrorNotificationWithGenericError,
} from "helpers/methods";
import { loginAction, selectAuthenticated } from "redux/authSlice";
import WarningIcon from "@mui/icons-material/Warning";
import { useTitle } from "utils/UseTitle";
import defaultImage from "assets/icons1/avatars/default.png";
// import { updatePreference } from "redux/preferencesSlice";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import urls from "global/constants/UrlConstants";
import { activateMFA, generateQRCodeForMFA } from "utils/service";
import setupMFAStyles from "./SetupMFA.styles";
import { CustomButton } from "global/components";
import loginLogo from "assets/images/loginLogo.svg";

interface CustomProps {
  location: any;
}

const SetupMFA = (props: CustomProps) => {
  useTitle("Setup MFA | RFPPro");
  const dispatch = useAppDispatch();
  const classes = setupMFAStyles;
  const email = props.location.state?.email ?? "";
  const password = props.location.state?.password ?? "";
  const token = props.location.state?.token ?? "";
  const isLoggedIn = useAppSelector(selectAuthenticated);
  const componentRef = useRef<any>(null);
  const [payload, setPayload] = useState({
    email: email,
    code: "",
    password: password,
  });
  const [processing, setProcessing] = useState<boolean>(false);
  const [qr, setQR] = useState<string>();

  useEffect(() => {
    if (isTruthy(token)) {
      makeQRCode();
    } else {
      history.push(urls.LOGIN_VIEW_PATH);
    }
  }, []);

  const makeQRCode = async () => {
    try {
      console.log("tokenn ", token)
      const response = await generateQRCodeForMFA(token);
      await generateQRCodeImage(response.message);
    } catch (error: any) {
      openErrorNotificationWithGenericError(error);
    }
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const verifyCode = async () => {
    try {
      if (!isValidOTP(payload.code)) {
        return openErrorNotification("Please enter the code");
      }
      setProcessing(true);
      const user = await activateMFA(
        token,
        payload.email,
        payload.password,
        payload.code
      );
      const formattedResources = convertResourceToObjectFormat(user.resources);
      if (isTruthy(formattedResources)) {
        dispatch(
          loginAction({
              authenticated: true,
              accessToken: user.authToken,
              userName: user.name,
              userRole: user.role,
              userAccount: user.account,
              userEmail: user.email,
              resources: formattedResources,
              trial: user.trial,
              app: user.app,
          })
        );
        // dispatch(
        //   updatePreference({
        //     layout: user.userPreferences?.layout ?? "vertical",
        //     theme: user.userPreferences?.theme ?? "light",
        //   })
        // );
      } else {
        openErrorNotification("Something went wrong.");
      }
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
      openErrorNotification(error.message);
    }
  };

  const generateQRCodeImage = async (url: string) => {
    try {
      const qrCodeImage = await QRCode.toDataURL(url);
      setQR(qrCodeImage);
    } catch (error: any) {
      openErrorNotification(error);
    }
  };

  const staticImageComponent = () => {
    return (
      <>
        <Grid container sx={classes.staticHeaderWrapper}>
          <Grid item>
            <img src={loginLogo} width="100%" height={"20"} alt="loginLogo" />
          </Grid>
          <Grid item display={"flex"} gap={2}>
            <Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleOnChange = (event: any) => {
    const inputValue = event.target.value;
    if (inputValue === '' || /^\d+$/.test(inputValue)) {
      setPayload({
        ...payload,
        code: event?.target.value,
      })
    }
  }

  const mfaComponent = () => {
    return <Box sx={classes.getLoginScreen} ref={componentRef}>
        <Typography sx={classes.getHeading} variant="h1">
          Keep your account secure
          </Typography>
          <Typography sx={classes.getSubHeading} color="text.secondary" variant="h4" >
              Set up the multi-factor authentication for your account.
            </Typography>
        {renderSetupMFAScreen()}
        <CustomButton
              label={
                <Typography variant="h6" sx={classes.signInText}>
                  Secure
                </Typography>
              }
              onClick={verifyCode}
              disabled={false}
              loading={false}
              customClasses={classes.signBtn}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
            >
              <Link
              color= "white"
                underline="hover"
                variant="subtitle2"
                onClick={() => history.push(urls.LOGIN_VIEW_PATH)}
              >
                Back to Login
              </Link>
            </Box>
        <Box sx={classes.allRightReservedWrapper}>
          <Typography sx={classes.footerTypo} variant="subtitle1">
            &copy; {getYear()} RFPPro. All Rights Reserved
          </Typography>
        </Box>
      </Box>
  };

  const renderSetupMFAScreen = () => {
    return (
      <Card elevation={0}>
        <CardContent
          sx={{
            py: 1,
            px: 3,
          }}
        >
          <Stack spacing={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-start"
              sx={{
                backgroundColor: "#faf7ea",
                p: 1,
                borderRadius: "8px",
              }}
            >
              <WarningIcon fontSize="small" htmlColor="#fe9e1c" />
              <Typography variant="body2">
                This setting is mandatory to access your account.
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
            >
              <img src={qr} height={150} width={150} />
            </Stack>
            <Typography variant="body2">
              Please scan the QR code with a multi-factor authentication app
              such as Google Authenticator or Authy, then enter the
              auth code displayed by the app.
            </Typography>
            <TextField
                type="text"
                onChange={(event: any) =>
                  handleOnChange(event)
                }
                value={payload.code}
                inputProps={{
                  // pattern: '[0-9]*',
                  maxLength: 6
                }}
              />


          </Stack>
        </CardContent>
      </Card>
    );
  };

  if (isLoggedIn) {
    history.push(urls.LANDING_VIEW_PATH);
    return null;
  } else {
    return <Grid container sx={classes.mainWrapper}>
    <Grid
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={classes.staticImageComponentWrapper}
    >
      {staticImageComponent()}
    </Grid>

    <Grid
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={classes.getComponentBasedOnURLWrapper}
    >
      <Grid
        container
        sx={{
          ...classes.innerWrapper,
        }}
      >
        <Grid item xl={3.4} lg={4.5} md={6} sm={9} xs={10}>
          {mfaComponent()}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  }
};

export default SetupMFA;
