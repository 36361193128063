export const billingHeader = [
  {
    name: "Invoice Number",
    field: "invoiceNumber",
  },
  {
    name: "Plan",
    field: "planName",
  },
  {
    name: "Start Date",
    field: "startDate",
  },
  {
    name: "End Date",
    field: "endDate",
  },
  {
    name: "Status",
    field: "paymentStatus",
  },
  {
    name: "Action",
    field: "action",
  },
];
