import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";
import { store } from "../../utils/store";

export const fetchProfileDetails = async () => {
  try {
    console.log("store.getState().auth", store.getState().auth)
    const email = store.getState().auth.userEmail;
    const url = `${urls.GET_USER_PROFILE}/${email}`;
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchQRCodeForMFA = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.GET_QR_CODE, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const verifyMFACode = async (
  password: string,
  authenticatorCode: string,
  multiFactorLogin: boolean
) => {
  try {
    const body = {
      email: store.getState().auth.userEmail,
      pwd: password,
      authenticatorCode,
      multiFactorLogin,
    };
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(urls.VERIFY_MFA, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
