import React from "react";
import { Box } from "@mui/material";

const KnowledgeBased = () => {
  return (
    <>
      <Box>
        <h1>KnowledgeBased</h1>
      </Box>
    </>
  );
};

export default KnowledgeBased;
