import { theme } from "utils/styles";
import loginScreenImage from "../../assets/images/loginScreenImage.svg";
  
  const setupMFAStyles = {
    getLoginScreen: {
        backgroundColor: "#373854",
        borderRadius: "46px",
        pt: { xl: 3, lg: 3, md: 4, sm: 4, xs: 4 },
        pb: { xl: 3, lg: 2, md: 3, sm: 3, xs: 3 },
      },
      innerGetLoginBox: {
        maxWidth: "100%",
        maxHeight: "100%",
      },
      getHeading: {
        color: "#ffffff",
        textAlign: "center",
        [`@media screen and (min-width: ${1200}px)`]: {
          fontSize: "28px",
        },
      },
      getSubHeading: {
        color: "#ffffff",
        // fontWeight: 700,
        paddingBottom: "8px",
        fontFamily: "Source Sans 3",
        [theme.breakpoints.up("xl")]: {
          mt: 1,
          fontSize: "22px",
        },
        [theme.breakpoints.up("lg")]: {
          mt: 1,
        },
        [theme.breakpoints.down("md")]: {
          mt: 1,
        },
        textAlign: "center",
        [`@media screen and (min-width: ${1200}px)`]: {
          fontSize: "12px",
        },
      },
      footerTypo: {
        textAlign: "center",
        color: "#BEBEBE",
        fontFamily: "Source Sans 3",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "10px",
        letterSpacing: "0.0em",
        wordBreak: "break-all",
      },
      allRightReservedWrapper: {
        display: "flex",
        justifyContent: "center",
        mt: { xl: 5, lg: 4, md: 4, sm: 4, xs: 2 },
      },
      staticImageComponentWrapper:{
        minHeight: "10vh"
      },
      mainWrapper: {
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#14142D",
      },
      getComponentBasedOnURLWrapper:{
        height: `calc(100-12)vh`,
        marginBottom: {
          xl: "10vh",
          lg: "2vh",
          md: "2vh",
          sm: "2vh",
          xs: "5vh",
        },
      },
      innerWrapper: {
        backgroundImage: "url(" + loginScreenImage + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("xl")]: {
          mt: 0,
        },
      },
      staticHeaderWrapper: {
        display: "flex",
        justifyContent: { sm: "space-between", xs: "center" },
        px: 5,
        py: {xl:4,lg:2,md:2,sm:2,xs:2},
        gap: { sm: 0, xs: 2 },
      },
      headerButtonStyle: {
        width: { sm: "175px", xs: "160px" },
        background: "#7A81FD",
        borderRadius: "35px",
        padding: "0 20px",
      },
      signInText: {
        fontWeight: 600,
        textAlign: "center",
        fontStyle: "normal",
        lineHeight: "23px",
      },
      signBtn: {
        width: "100%",
        background: "#7A81FD",
        borderRadius: "8px",
        fontSize: "18px !important",
        fontWeight: 500,
        fontFamily: "Verdana !important",
      },
  } as const;
  
  export default setupMFAStyles;
  