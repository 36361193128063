import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Box,
  Typography,
  Button,
  makeStyles,
} from "@mui/material";
import ManageMFAModal from "./ManageMFAModal";
import { useState } from "react";
import strings from "../../../global/constants/StringConstants";
import { useTitle } from "utils/UseTitle";
import { useAppSelector } from "utils/hooks";
import { selectBackgroundColor } from "redux/themeChangeSlice";
import { Theme } from "@material-ui/core";
import { appColor, customScrollCssInner, theme } from "utils/styles";


interface CustomProps {
  mfaEnabled: boolean;
  updateMFAStatus: Function;
}

const Security = (props: CustomProps) => {
  useTitle(strings.security);
  const bgcolor = useAppSelector(selectBackgroundColor);
  const [manageMFA, setManageMFA] = useState<boolean>(false);

  const getMFAQRCodeModal = () => {
    return (
      <ManageMFAModal
        visible={manageMFA}
        handleDialogClose={() => setManageMFA(false)}
        updateMFAStatus={props.updateMFAStatus}
        mfaEnabled={props.mfaEnabled}
      />
    );
  };

  const getMFASection = () => {
    return (
      <Card sx={{backgroundColor: bgcolor ? "#282844" : "#ffffff",}}>
        <CardHeader sx={{backgroundColor: bgcolor ? "#282844" : "#ffffff", color: bgcolor ? "#CBCBCB" : "#000000"}} title="Multi Factor Authentication" />
        <CardContent sx={{ p: 0, m: 1, backgroundColor: bgcolor ? "#282844" : "#ffffff", }}>
          <Grid container sx={{ padding: 1, backgroundColor: bgcolor ? "#282844" : "#ffffff" }}>
            <Grid xs={12} sm={6} sx={{backgroundColor: bgcolor ? "#282844" : "#ffffff" }}>
              <Card sx={{ height: "100%", backgroundColor: bgcolor ? "#282844" : "#ffffff", }} variant="outlined">
                <CardContent>
                  <Box
                    sx={{
                      display: "block",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        "&::before": {
                          backgroundColor: props.mfaEnabled
                            ? "success.main"
                            : "error.main",
                          borderRadius: "50%",
                          content: '""',
                          display: "block",
                          height: 8,
                          left: 4,
                          position: "absolute",
                          top: 7,
                          width: 8,
                          zIndex: 1,
                        },
                      }}
                    >
                      <Typography color="error" sx={{ pl: 3, color: props.mfaEnabled ? "green" : "red"}} variant="body2">
                        {props.mfaEnabled ? "On" : "Off"}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography sx={{ mt: 1, color: bgcolor ? "#CBCBCB" : "#000000"}} variant="subtitle2">
                    Authenticator App
                  </Typography>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    sx={{ mt: 1, color: bgcolor ? "#CBCBCB" : "#000000"}}
                  >
                    Use an authenticator app to generate one time security
                    codes.
                  </Typography>
                  <Box sx={{ mt: 4 }}>
                    <Button
                      variant="outlined"
                      color="inherit"
                      onClick={() => setManageMFA(true)}
                      size="small"
                      sx={{ color: bgcolor ? "#CBCBCB" : "#000000"}}
                    >
                      {props.mfaEnabled ? "Disable" : "Set Up"}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      {getMFASection()}
      {getMFAQRCodeModal()}
    </>
  );
};

export default Security;
